import { useMemo } from "react";

import { RepositoryType } from "api/github/github.types";
import { languageToRgb } from "./card.utils";

import styles from "./card.module.css";
import { ReactComponent as RepoSvg } from "assets/repository.svg";
import { ReactComponent as StarSvg } from "assets/star.svg";
import { ReactComponent as ForkSvg } from "assets/fork.svg";

type CardPropsType = {
  repository: RepositoryType;
};

export const Card: React.FC<CardPropsType> = ({ repository }) => {
  const background = useMemo(() => {
    return languageToRgb(repository.language);
  }, [repository.language]);
  return (
    <div className={styles.wrapper}>
      {repository.stargazers_count > 250 && <div className={styles.glow} />}
      <div className={styles.card}>
        <div className={styles.title}>
          <RepoSvg />
          <a className={styles.url} href={repository.html_url} target="blank">
            {repository.name}
          </a>
          {repository.homepage && (
            <a className={styles.docs} href={repository.homepage} target="blank">
              Documentation
            </a>
          )}
        </div>
        <div className={styles.description}>{repository.description}</div>
        <div className={styles.spacer} />
        <div className={styles.footer}>
          <div className={styles.info}>
            <span className={styles.language} style={{ background }} />
            {repository.language}
          </div>
          <a className={styles.info} href={`${repository.html_url}/stargazers`}>
            <StarSvg />
            {repository.stargazers_count}
          </a>
          {!!repository.forks_count && (
            <a className={styles.info} href={`${repository.html_url}/network/members`}>
              <ForkSvg /> {repository.forks_count}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};
