/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unknown-property */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMemo, useRef } from "react";
import { a } from "@react-spring/three";
import { BufferGeometry, Color, Material, Mesh, ShaderMaterial, Vector4 } from "three";
import { useFrame } from "@react-three/fiber";
import { useWindowSize } from "@better-typed/react-window-hooks";

import { useAnimation } from "./animation";
import { SphereMaterial } from "./material";
import { useSection } from "store/section.store";

let time = 0;

const surfaceColor1 = new Color("#000");
const surfaceColor2 = new Color("#bc6113");
const surfaceColor3 = new Color("#173640");
const insideColor1 = new Color("#030d22");
const insideColor2 = new Color("#2a556f");
const insideColor3 = new Color("#051c3c");

const FinalMaterial = a((props: any) => {
  const ref = useRef<ShaderMaterial | null>(null);

  useFrame((_, delta) => {
    if (ref.current) {
      (ref.current as any).time = time + delta;
      time = (ref.current as any).time;
    }
  });

  return (
    // @ts-ignore
    <sphereMaterial
      ref={ref}
      key={SphereMaterial.key}
      {...props}
      surfaceColor1={surfaceColor1}
      surfaceColor2={surfaceColor2}
      surfaceColor3={surfaceColor3}
      insideColor1={insideColor1}
      insideColor2={insideColor2}
      insideColor3={insideColor3}
    />
  );
});

export const Sphere = () => {
  const meshRef = useRef<Mesh<BufferGeometry, Material | Material>>({} as Mesh<BufferGeometry, Material | Material>);
  const { section, setSection } = useSection((state) => ({ section: state.section, setSection: state.setSection }));

  const animation = useAnimation();

  const [width, height] = useWindowSize();

  const resolution = useMemo(() => {
    const aspect = 1;

    if (height / width > aspect) {
      const z = (width / height) * aspect;
      return new Vector4(width, height, z, 1);
    }

    const w = (height / width) * aspect;
    return new Vector4(width / 100, height / 100, 1, w);
  }, [height, width]);

  const initialize = () => {
    if (section === 0) {
      setSection(1);
    }
  };

  return (
    <a.mesh
      ref={meshRef}
      rotation={[0, 0, 0]}
      position={[0, 0, -Math.min(width, height) / 2]}
      onAfterRender={initialize}
    >
      <planeGeometry args={[width, height]} />
      {/* @ts-ignore */}
      <FinalMaterial resolution={resolution} {...animation} />
    </a.mesh>
  );
};
