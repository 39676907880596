import { Container, Title, SecondaryText, MainText, PrimaryText } from "components";

import styles from "./about.module.css";

const delay = 80;

export const About: React.FC = () => {
  return (
    <Container className={styles.container}>
      <Title className={styles.title}>
        <MainText slide="right">Who We Are</MainText>
      </Title>
      <div className={styles.row}>
        <div className={styles.description}>
          <SecondaryText slide="bottom" delay={1 * delay}>
            We are a group of specialists who decided to join forces to create the highest class open source products.
            Together, our experience is a complete cross-section of software development. Thanks to this, we care about
            development experience and integrations from many perspectives. We aim high, we create quality packages and
            empower the open source community growth.
          </SecondaryText>
        </div>
        <div className={styles.events}>
          <div className={styles.event}>
            <b className={styles.eventYear}>
              <PrimaryText slide="right">2021</PrimaryText>
            </b>
            <div className={styles.eventDescription}>
              <PrimaryText slide="right">Founded in Poland</PrimaryText>
            </div>
          </div>
          <div className={styles.event}>
            <b className={styles.eventYear}>
              <PrimaryText slide="left">2022</PrimaryText>
            </b>
            <div className={styles.eventDescription}>
              <PrimaryText slide="left">First open source releases</PrimaryText>
            </div>
          </div>
          <div className={styles.event}>
            <b className={styles.eventYear}>
              <PrimaryText slide="right">Now</PrimaryText>
            </b>
            <div className={styles.eventDescription}>
              <PrimaryText slide="right">We are creating the future</PrimaryText>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
