import { memo } from "react";
import { Canvas } from "@react-three/fiber";
// import { Perf } from "r3f-perf";

import { Music } from "./effects/music";
import { Postprocessing } from "./effects/postprocessing";
import { Sphere } from "./sphere/sphere";

import styles from "./webgl.module.css";

export const Webgl = memo(() => {
  return (
    <div className={styles.container}>
      <Canvas linear gl={{ antialias: false }} dpr={Math.min(window.devicePixelRatio, 1.3)}>
        <Sphere />
        <Postprocessing />
        <Music />
        {/* <Perf /> */}
      </Canvas>
    </div>
  );
});
