import create from "zustand";

type SoundState = {
  playing: boolean;
  setPlaying: (playing: boolean) => void;
};

export const useSound = create<SoundState>((set) => ({
  playing: false,
  setPlaying: (playing: boolean) => set(() => ({ playing })),
}));
