/* eslint-disable react/no-unknown-property */
import React, { useRef, useState } from "react";
import { AudioLoader, AudioListener, PositionalAudio } from "three";
import { useLoader, useThree } from "@react-three/fiber";
import { useDidRender, useDidUpdate } from "@better-typed/react-lifecycle-hooks";

import { useSound } from "store/music.store";

export const Music: React.FC = () => {
  const sound = useRef<PositionalAudio | null>(null);
  const { camera } = useThree();
  const [listener] = useState(() => new AudioListener());
  // Free license https://soundcloud.com/keysofmoon/spatium-calm-ambient-music-free-download
  const buffer = useLoader(AudioLoader, "./sound.mp3");
  const playing = useSound((state) => state.playing);

  const handlePlayState = () => {
    if (sound.current) {
      if (playing && !sound.current.isPlaying) {
        sound.current.play();
      } else if (!playing && sound.current.isPlaying) {
        sound.current.stop();
      }
    }
  };

  useDidRender(() => {
    if (sound.current) {
      sound.current.setBuffer(buffer);
      sound.current.setRefDistance(1);
      sound.current.setLoop(true);
      sound.current.setVolume(0.08);
      handlePlayState();
      camera.add(listener);
    }
    return () => camera.remove(listener);
  }, []);

  useDidUpdate(() => {
    handlePlayState();
  }, [playing]);

  return <positionalAudio ref={sound} args={[listener]} />;
};
