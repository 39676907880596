/* eslint-disable react/no-array-index-key */
import React from "react";
import { animated, useSpring } from "react-spring";

const Letter: React.FC<{ children: React.ReactNode; index: number }> = ({ children, index }) => {
  const from = { opacity: 0 };
  const to = { opacity: 1 };
  const delay = Math.random() * 600 + 100 + index;
  const animation = useSpring({ from, to, delay, config: { mass: 0.4, tension: 10, friction: 15 } });
  return <animated.span style={animation}>{children}</animated.span>;
};

export const PrimaryText: React.FC<{ children: string; slide: "left" | "right" }> = ({ children, slide }) => {
  const to = { x: 0, opacity: 1 };
  const from = slide === "left" ? { x: -100, opacity: 0 } : { x: 100, opacity: 0 };

  const animation = useSpring({ from, to, config: { mass: 0.2, tension: 10, friction: 6 } });

  return (
    <animated.div style={animation}>
      {children.split("").map((item, index) => (
        <Letter index={index} key={index}>
          {item}
        </Letter>
      ))}
    </animated.div>
  );
};
