function hashCode(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i += 1) {
    // eslint-disable-next-line no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

export function languageToRgb(language: string) {
  switch (language.toLocaleLowerCase()) {
    case "typescript": {
      return "#3178c6";
    }
    default: {
      const int = hashCode(language);
      // eslint-disable-next-line no-bitwise
      const c = (int & 0x00ffffff).toString(16).toUpperCase();

      return "00000".substring(0, 6 - c.length) + c;
    }
  }
}
