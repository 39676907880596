import clsx from "clsx";

import styles from "./link.module.css";

type TitlePropsType = {
  children: React.ReactNode;
} & React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>;

export const Link: React.FC<TitlePropsType> = ({ children, ...rest }) => {
  return (
    <a {...rest} className={clsx(styles.link, rest.className)}>
      {children}
    </a>
  );
};
