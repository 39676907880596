/* eslint-disable react/no-array-index-key */
import React from "react";
import { animated, useSpring } from "react-spring";

export const SecondaryText: React.FC<{
  children: React.ReactNode;
  slide: "top" | "left" | "right" | "bottom";
  delay?: number;
}> = ({ children, slide, delay = 0 }) => {
  const key = ["top", "bottom"].includes(slide) ? "y" : "x";
  const value = ["top", "left"].includes(slide) ? -30 : 30;
  const from = { [key]: value };
  const to = { [key]: 0 };
  const fromO = { opacity: 0 };
  const toO = { opacity: 1 };

  const animation = useSpring({ from, to, delay: 200 + delay, config: { mass: 0.5, tension: 6, friction: 5 } });
  const animationOpacity = useSpring({
    from: fromO,
    to: toO,
    delay: 200 + delay,
    config: { mass: 0.5, tension: 8, friction: 14 },
  });

  return <animated.div style={{ ...animation, ...animationOpacity }}>{children}</animated.div>;
};
