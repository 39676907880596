import React from "react";
import { EffectComposer, Vignette, SMAA } from "@react-three/postprocessing";

export const Postprocessing: React.FC = () => {
  return (
    <EffectComposer>
      <Vignette eskil={false} offset={0.1} darkness={0.8} />
      <SMAA />
    </EffectComposer>
  );
};
