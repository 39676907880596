import clsx from "clsx";

import styles from "./title.module.css";

type TitlePropsType = {
  children: React.ReactNode;
  className?: string;
};

export const Title: React.FC<TitlePropsType> = ({ children, className }) => {
  return <h2 className={clsx(styles.title, className)}>{children}</h2>;
};
