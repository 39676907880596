import { useMemo } from "react";
import { Vector3 } from "three";
import { useSpring } from "@react-spring/three";

import { useAnimating } from "store/animation.store";
import { useSection } from "store/section.store";
import { useProportion } from "./proportion";

export type Animation = typeof animations[0];

const animations = [
  // Loading
  {
    position: [22, 0, -25],
    rotation: [0, 0, 0],
    sphereSize: 0.5,
    sdfSize: 0,
    sdfModifier: 0.3,
    sdfScale: 1,
    glow: 0.02,
  },
  // Header
  {
    position: [0, 0, -2],
    rotation: [0.3, 0.4, -0.2],
    sphereSize: 0.5,
    sdfSize: 0,
    sdfModifier: 0.3,
    sdfScale: 1,
    glow: 0.02,
  },
  // About
  {
    position: [-2.5, 0, -0.5],
    rotation: [0.2, 1.1, 0],
    sphereSize: 0.4,
    sdfSize: 0,
    sdfModifier: 0.3,
    sdfScale: 1,
    glow: 0.02,
  },
  // Projects
  {
    position: [0, -0.2, -6],
    rotation: [-0.4, 0.4, 0],
    sphereSize: 0.5,
    sdfSize: 1.2,
    sdfModifier: 0.2,
    sdfScale: 0.9,
    glow: 0.001,
  },
  // Support
  {
    position: [-1.2, -0.2, -6],
    rotation: [0, 0.3, 0.6],
    sphereSize: 0.4,
    sdfSize: 1.4,
    sdfModifier: 0.23,
    sdfScale: 1.9,
    glow: 0.001,
  },
  // Contact
  {
    position: [-0.5, 5.5, -1.3],
    rotation: [-0.1, -0.8, -0.5],
    sphereSize: 0.5,
    sdfSize: 0,
    sdfModifier: 0.3,
    sdfScale: 1,
    glow: 0.02,
  },
];

const getAnimationState = (targetAnimation: Animation, proportion: readonly [number, number, number]) => {
  const x = targetAnimation.position[0] * proportion[0];
  const y = targetAnimation.position[1] * proportion[1];
  const z = targetAnimation.position[2] * proportion[2];

  return {
    ...targetAnimation,
    position: [x, y, z] as unknown as Vector3,
  };
};

export const useAnimation = () => {
  const section = useSection((state) => state.section);
  const setAnimating = useAnimating((state) => state.setAnimating);
  const proportion = useProportion();

  const values = useMemo(() => {
    const target = animations[section];

    return getAnimationState(target, proportion);
  }, [section, proportion]);

  const animation = useSpring({
    ...values,
    config: { mass: 0.1, tension: 10, friction: 20 },
    onChange: () => {
      setAnimating(true);
    },
    onStart: () => {
      setAnimating(true);
    },
    onResolve: () => {
      setAnimating(false);
    },
  });

  return animation;
};
