import { useFetch } from "@better-typed/react-hyper-fetch";

import { getRepositories } from "api";
import { Container, Description, Link, MainText, SecondaryText, Title } from "components";
import { Card } from "./card/card";

import styles from "./projects.module.css";

const command = getRepositories.setParams({ account: "BetterTyped" }).setQueryParams({ per_page: 6 });

// Prefetching
command.send();

export const Projects: React.FC = () => {
  const { data } = useFetch(command);

  return (
    <Container className={styles.container}>
      <Title className={styles.title}>
        <MainText slide="left">We Create and Challenge</MainText>
      </Title>
      <Description className={styles.description}>
        <SecondaryText slide="right">
          Here is what we have created so far and which we are extremely proud of.
        </SecondaryText>
      </Description>
      <div className={styles.row}>
        {data?.map((repository, index) => (
          <SecondaryText key={repository.id} slide="top" delay={index * 200}>
            <Card repository={repository} />
          </SecondaryText>
        ))}
      </div>
      <SecondaryText slide="right">
        <Link href="https://github.com/BetterTyped" target="blank" className={styles.link}>
          See more projects
        </Link>
      </SecondaryText>
    </Container>
  );
};
