import clsx from "clsx";

import styles from "./description.module.css";

type DescriptionPropsType = {
  children: React.ReactNode;
  className?: string;
};

export const Description: React.FC<DescriptionPropsType> = ({ children, className }) => {
  return <div className={clsx(styles.description, className)}>{children}</div>;
};
