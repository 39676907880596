import clsx from "clsx";

import { Container, Description, MainText, SecondaryText, Title } from "components";

import styles from "./partners.module.css";

export const Partners: React.FC = () => {
  return (
    <Container className={styles.container}>
      <div className={clsx(styles.partners)}>
        <Title className={clsx(styles.title)}>
          <MainText slide="left">Be our partner</MainText>
        </Title>
        <Description className={clsx(styles.description)}>
          <SecondaryText slide="bottom">
            We were created to change the world, but to make it possible, we need to keep balance.
          </SecondaryText>
        </Description>
      </div>
      <div className={clsx(styles.row)}>
        <div className={clsx(styles.goal)}>
          <div className={clsx(styles.name)}>
            <MainText slide="right">Develop.</MainText>
          </div>
          <div className={clsx(styles.details)}>
            <SecondaryText slide="bottom">
              We believe in the power of creativity and our goal is simple. We provide the highest quality open source
              software. For this to be fully successful, we follow strict guidelines and maintain high test coverage.
            </SecondaryText>
          </div>
        </div>
        <div className={clsx(styles.goal)}>
          <div className={clsx(styles.name)}>
            <MainText slide="right" delay={500}>
              Integrate.
            </MainText>
          </div>
          <div className={clsx(styles.details)}>
            <SecondaryText slide="bottom" delay={300}>
              You can help us by integrating our solutions with your software. This way you will speed up your work and
              we will receive valuable feedback. Your experience with our tools allow us to develop better features.
            </SecondaryText>
          </div>
        </div>
        <div className={clsx(styles.goal)}>
          <div className={clsx(styles.name)}>
            <MainText slide="right" delay={1000}>
              Partner.
            </MainText>
          </div>
          <div className={clsx(styles.details)}>
            <SecondaryText slide="bottom" delay={600}>
              We have the ambition to become a place that will allow us to unleash creativity and ingenuity. It is a
              difficult and very ambitious goal that we can achieve with your support, cooperation and sponsorship.
            </SecondaryText>
          </div>
        </div>
      </div>
    </Container>
  );
};
