import create from "zustand";

type SectionState = {
  loaded: boolean;
  section: number;
  prevSection: number;
  setLoaded: (loaded: boolean) => void;
  setSection: (section: number) => void;
  reset: () => void;
  prev: () => void;
  next: () => void;
};

export const useSection = create<SectionState>((set) => ({
  section: 0,
  prevSection: 0,
  loaded: false,
  setLoaded: (loaded: boolean) => set(() => ({ loaded })),
  setSection: (section: number) => set((state) => ({ section, prevSection: state.section })),
  next: () =>
    set((state) => {
      if (state.section < 5) {
        return { section: state.section + 1, prevSection: state.section };
      }
      return {};
    }),
  prev: () =>
    set((state) => {
      if (state.section > 1) {
        return { section: state.section - 1, prevSection: state.section };
      }
      return {};
    }),
  reset: () => set((state) => ({ section: 1, prevSection: state.section })),
}));
