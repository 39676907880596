import { useWindowSize } from "@better-typed/react-window-hooks";

import { SecondaryText } from "components/animations";
import { useSection } from "store/section.store";
import { Container } from "../container/container";
import { MobileMenu } from "./mobile-menu";

import styles from "./navbar.module.css";

const sections = [
  { name: "About", id: 2 },
  { name: "Projects", id: 3 },
  { name: "Support", id: 4 },
  { name: "Contact", id: 5 },
];

export const Navbar: React.FC = () => {
  const [width] = useWindowSize();
  const { loaded, section, setSection } = useSection((state) => ({
    loaded: state.loaded,
    section: state.section,
    setSection: state.setSection,
  }));

  if (section === 0 || !loaded) {
    return null;
  }

  const isMobile = width > 800;

  return (
    <Container className={styles.container}>
      <SecondaryText slide="top">
        <div className={styles.navbar}>
          <button type="button" className={styles.title} onClick={() => setSection(1)}>
            BetterTyped
          </button>
          <div className={styles.spacer} />
          {isMobile && (
            <div className={styles.routing}>
              {sections.map((s) => (
                <button
                  key={String(s.id)}
                  type="button"
                  className={`${styles.btn} ${s.id === section ? styles.active : ""}`}
                  onClick={() => setSection(s.id)}
                >
                  {s.name}
                </button>
              ))}
            </div>
          )}
          {!isMobile && <MobileMenu />}
        </div>
      </SecondaryText>
    </Container>
  );
};
