import { useDidMount } from "@better-typed/react-lifecycle-hooks";
import { useDebounce } from "@better-typed/react-performance-hooks";

import { SecondaryText } from "components/animations";
import { useSection } from "store/section.store";

import styles from "./loader.module.css";

export const Loader = () => {
  const { debounce } = useDebounce(2500);
  const setLoaded = useSection((state) => state.setLoaded);

  useDidMount(() => {
    debounce(() => {
      setLoaded(true);
    });
  });

  return (
    <div className={styles.container}>
      <div className={styles.subtitle}>
        <SecondaryText slide="left">Welcome to</SecondaryText>
      </div>
      <div className={styles.title}>
        <SecondaryText slide="right" delay={500}>
          BetterTyped
        </SecondaryText>
      </div>
    </div>
  );
};
