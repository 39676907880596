/* eslint-disable react/no-array-index-key */
import { useDidUpdate } from "@better-typed/react-lifecycle-hooks";
import { useDebounce } from "@better-typed/react-performance-hooks";
import clsx from "clsx";
import React, { useState } from "react";

import styles from "./hide.module.css";

export const hideDuration = 400;

export const Hide: React.FC<{
  children: React.ReactNode;
  visible?: boolean;
  initialUnmount?: boolean;
}> = ({ children, visible, initialUnmount = false }) => {
  const [unmount, setUnmount] = useState(initialUnmount);
  const { debounce } = useDebounce(hideDuration);

  useDidUpdate(() => {
    debounce(() => {
      setUnmount(!visible);
    });
  }, [visible]);

  if (unmount) {
    return null;
  }

  return <div className={clsx([styles.container, { [styles.hide]: !visible }])}>{children}</div>;
};
