import { Container, Description, MainText, Title, SecondaryText } from "components";

import styles from "./contact.module.css";

const emails = ["contact@bettertyped.com"];

export const Contact: React.FC = () => {
  return (
    <Container className={styles.container} contentClassName={styles.wrapper}>
      <Title className={styles.title}>
        <MainText slide="right">Contact us</MainText>
      </Title>
      <Description className={styles.description}>
        <SecondaryText delay={300} slide="bottom">
          Do you have a proposal for us or do you want to cooperate? Let&apos;s get in touch
        </SecondaryText>
        <SecondaryText delay={300} slide="bottom">
          Storecommerce Sp. z o.o. | NIP 6751776000 | Poland, Aleja Powstania Warszawskiego 15, 31-539 Cracow
        </SecondaryText>
      </Description>
      <div className={styles.emails}>
        <div className={styles.content}>
          {emails.map((email, index) => (
            <a className={styles.email} key={email} href={`mailto:${email}`}>
              <SecondaryText delay={index * 500} slide="bottom">
                {email}
              </SecondaryText>
            </a>
          ))}
        </div>
      </div>
    </Container>
  );
};
