import { animated, useSpring } from "react-spring";

import { useSection } from "store/section.store";

import styles from "./scroll-down.module.css";

export const ScrollDown: React.FC = () => {
  const section = useSection((state) => state.section);

  const from = { y: 0 };
  const to = { y: 10 };
  const fromO = { opacity: 1 };
  const toO = { opacity: 0 };

  const isHeader = section === 1;

  const animation = useSpring({
    from: isHeader ? to : from,
    to: isHeader ? from : to,
    config: { mass: 0.5, tension: 6, friction: 5 },
  });
  const animationOpacity = useSpring({
    from: isHeader ? toO : fromO,
    to: isHeader ? fromO : toO,
    config: { mass: 0.2, tension: 2, friction: 1 },
  });

  return (
    <animated.div className={styles.container} style={{ ...animation, ...animationOpacity }}>
      <div className={styles.line} />
      <div className={styles.title}>Scroll Down</div>
    </animated.div>
  );
};
