import { extend } from "@react-three/fiber";
import { shaderMaterial } from "@react-three/drei";
import glsl from "babel-plugin-glsl/macro";
import { Color, Vector3, Vector4 } from "three";

// This shader is from Bruno Simons Threejs-Journey: https://threejs-journey.xyz
const SphereMaterial = shaderMaterial(
  {
    time: 0,
    resolution: new Vector4(1000, 1000, 0, 1),
    scale: 0.3,
    sdfSize: 0,
    sdfScale: 0,
    sdfModifier: 0,
    sphereSize: 0.5,
    glow: 0.3,
    rotation: new Vector3(0, 0, 0),
    position: new Vector3(0, 0, -10),
    surfaceColor1: new Color("black"),
    surfaceColor2: new Color("black"),
    surfaceColor3: new Color("black"),
    insideColor1: new Color("black"),
    insideColor2: new Color("black"),
    insideColor3: new Color("black"),
  },
  glsl`
  varying vec2 vUv;
  void main() {
    vec4 modelPosition = modelMatrix * vec4(position, 1.0);
    vec4 viewPosition = viewMatrix * modelPosition;
    vec4 projectionPosition = projectionMatrix * viewPosition;
    gl_Position = projectionPosition;
    vUv = uv;
  }
  `,
  glsl`
  uniform float time;
  uniform vec4 resolution;
  uniform float sdfSize;
  uniform float sdfModifier;
  uniform float sdfScale;
  uniform float sphereSize;
  uniform float glow;
  uniform vec3 rotation;
  uniform vec3 position;
  uniform vec3 surfaceColor1;
  uniform vec3 surfaceColor2;
  uniform vec3 surfaceColor3;
  uniform vec3 insideColor1;
  uniform vec3 insideColor2;
  uniform vec3 insideColor3;

  varying vec2 vUv;

  // Noise
  float mod289(float x){return x - floor(x * (1.0 / 289.0)) * 289.0;}
  vec4 mod289(vec4 x){return x - floor(x * (1.0 / 289.0)) * 289.0;}
  vec4 perm(vec4 x){return mod289(((x * 34.0) + 1.0) * x);}
  
  float noise(vec3 p){
      vec3 a = floor(p);
      vec3 d = p - a;
      d = d * d * (3.0 - 2.0 * d);
  
      vec4 b = a.xxyy + vec4(0.0, 1.0, 0.0, 1.0);
      vec4 k1 = perm(b.xyxy);
      vec4 k2 = perm(k1.xyxy + b.zzww);
  
      vec4 c = k2 + a.zzzz;
      vec4 k3 = perm(c);
      vec4 k4 = perm(c + 1.0);
  
      vec4 o1 = fract(k3 * (1.0 / 20.0));
      vec4 o2 = fract(k4 * (1.0 / 20.0));
  
      vec4 o3 = o2 * d.z + o1 * (1.0 - d.z);
      vec2 o4 = o3.yw * d.x + o3.xz * (1.0 - d.x);
  
      return o4.y * d.y + o4.x * (1.0 - d.y);
  }
  
  mat2 rotate2D(float angle){
    return mat2(
      cos(angle),-sin(angle),
      sin(angle),cos(angle)
    );
  }

  
  // Ray Marching
  
  mat3 rotation3dX(float angle) {
    float s = sin(angle);
    float c = cos(angle);
  
    return mat3(
      1.0, 0.0, 0.0,
      0.0, c, s,
      0.0, -s, c
    );
  }

  mat3 rotation3dY(float angle) {
    float s = sin(angle);
    float c = cos(angle);

    return mat3(
      c, 0.0, -s,
      0.0, 1.0, 0.0,
      s, 0.0, c
    );
  }

  mat3 rotation3dZ(float angle) {
    float s = sin(angle);
    float c = cos(angle);
  
    return mat3(
      c, s, 0.0,
      -s, c, 0.0,
      0.0, 0.0, 1.0
    );
  }

  float sphere(vec3 p) {
    return length(p) - sphereSize;
  }

  float SineCrazy(vec3 p) {
    return 1. - (sin(p.x) + sin(p.y) + sin(p.z))/(sdfModifier * 10.);
  }
  
  float scene(vec3 p) {
    vec3 pX = rotation3dX(rotation.x) * p;
    vec3 pY = rotation3dY(rotation.y) * pX;
    vec3 pZ = rotation3dZ(rotation.z) * pY;
    float scale = sdfScale * 10.;

    return max(sphere(pZ),(sdfSize - SineCrazy(pZ*scale))/scale);
  }

  vec3 getNormal(vec3 p) {
    vec2 o = vec2(0.001,0.);
    return normalize(
      vec3(
        scene(p + o.xyy) - scene(p - o.xyy),
        scene(p + o.yxy) - scene(p - o.yxy),
        scene(p + o.yyx) - scene(p - o.yyx)
      )
    );
  }

  
  // Outside
  
  float lines(vec2 uv, float offset){
    float lineWidth = 1.8;
    return smoothstep(
      0., 0.6 + offset * 0.5,
      0.5 * abs((sin(uv.x*lineWidth) + offset * 2.))
    );
  }

    
  vec3 getSurfaceColor(vec3 p1) {
    vec3 pX = rotation3dX(rotation.x) * p1;
    vec3 pY = rotation3dY(rotation.y) * pX;
    vec3 pZ = rotation3dZ(rotation.z) * pY;
    vec3 p = pZ;
    float speed = time / 4.8;
    float swirl = .08;
    float n = noise(p / swirl + speed);
  
    vec2 baseUV = rotate2D(n)*p.xy*10.;
    float basePattern = lines(baseUV, .5);
    float secondPattern = lines(baseUV, 0.2);

    vec3 baseColor = mix(surfaceColor2,surfaceColor3,basePattern);
    vec3 animatedLayer = mix(baseColor,surfaceColor1,secondPattern);

    return animatedLayer;
  }

  // Inside
      
  float insideLines(vec2 uv, float offset){
    float lineWidth = 3.4;
    return smoothstep(
      0., 0.6 + offset * 0.5,
      0.5 * abs((sin(uv.x*lineWidth) + offset * 2.))
    );
  }

  vec3 getInsideColor(vec3 p1) {
    vec3 pX = rotation3dX(rotation.x) * p1;
    vec3 pY = rotation3dY(rotation.y) * pX;
    vec3 pZ = rotation3dZ(rotation.z) * pY;
    vec3 p = pZ;
    float speed = time / 2.4;
    float swirl = .06;
    float n = noise(p / swirl + speed);
  
    vec2 baseUV = rotate2D(n)*p.xy*10.;
    float basePattern = insideLines(baseUV, .2);
    float secondPattern = insideLines(baseUV, 0.2);

    vec3 baseColor = mix(insideColor2,insideColor3,basePattern);
    vec3 animatedLayer = mix(baseColor,insideColor1,secondPattern);

    return animatedLayer;
  }

  // Glow

  vec3 getGlowColor(vec3 p) {
    float amount = clamp((1.8-length(p))/2.,0.,.2);
    vec3 col = 0.5 + 0.5 * cos(6.28319 * (vec3(.1, .2, 0.03) + amount * vec3(.15, 0.19, 1.8)));
    return col * amount * glow;
  }

  // Postprocessing
  float random(vec2 p) {
      vec2 K1 = vec2(
          23.14069263277926, // e^pi (Gelfond's constant)
           2.665144142690225 // 2^sqrt(2) (Gelfondâ€“Schneider constant)
      );
      return fract(cos(dot(p,K1)) * 12345.6789);
  }


  void main()	{
    vec2 newUV = (vUv - vec2(0.5)) * resolution.zw + vec2(0.5);
    vec2 p = newUV - vec2(0.5);
    vec2 uvrandom = vUv;
    uvrandom.y *= random(vec2(uvrandom.y, 0.4));

    vec3 camPos = vec3(0.,0.,2.);
    vec3 ray = normalize(vec3(p, -1.) + vec3(0.1 * position.x, 0.1 * position.y, position.z));
    vec3 rayPos = camPos;
    
    float curDist = 0.;
    float sphereDist = 0.;
    float rayLen = 0.;
    float accuracy = 0.0003;
    
    vec3 color =  vec3(0.,0.,0.);


    for(int i = 0;i <= 80; i++) {
      curDist = scene(rayPos);
      sphereDist = sphere(rayPos);
      rayLen += curDist;
      rayPos = camPos + ray * rayLen;
      
      if(abs(curDist) < accuracy && rayLen>1.) {
        if(abs(sphereDist) > accuracy) {
          // Inside
          color = getInsideColor(rayPos);
        } else {
          // Surface
          color = getSurfaceColor(rayPos);
        }
        break;
      } 
      if(rayLen>2.5) {
        break;
      }
      // Atmosphere
      color += getGlowColor(rayPos);
    }
    
    // Noise
    color += random(uvrandom) * 0.04;

    gl_FragColor = vec4(color,1.);
  }
  `,
);

extend({ SphereMaterial });

export { SphereMaterial };
