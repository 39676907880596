import create from "zustand";

type AnimatingState = {
  animating: boolean;
  setAnimating: (animating: boolean) => void;
  reset: () => void;
};

export const useAnimating = create<AnimatingState>((set) => ({
  animating: false,
  setAnimating: (animating: boolean) => set(() => ({ animating })),
  reset: () => set(() => ({ animating: false })),
}));
