import React from "react";
import ReactDOM from "react-dom/client";

import { Webgl } from "webgl/webgl";
import { Footer, Navbar, Interactive } from "components";
import { LandingPage } from "pages";
import { reportWebVitals } from "./reportWebVitals";

import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <Interactive>
      <Navbar />
      <Webgl />
      <LandingPage />
      <Footer />
    </Interactive>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
