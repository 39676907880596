import { Hide } from "components";
import { Loader } from "components/ui/loader/loader";
import { useSection } from "store/section.store";
import { About } from "./about/about";
import { Contact } from "./contact/contact";
import { Header } from "./header/header";
import { Partners } from "./partners/partners";
import { Projects } from "./projects/projects";

export const LandingPage = () => {
  const section = useSection((state) => state.section);
  const loaded = useSection((state) => state.loaded);
  const isLoaded = loaded && section !== 0;

  return (
    <>
      <Hide visible={!isLoaded}>
        <Loader />
      </Hide>
      {isLoaded && (
        <>
          <Hide visible={section === 1}>
            <Header />
          </Hide>
          <Hide visible={section === 2} initialUnmount>
            <About />
          </Hide>
          <Hide visible={section === 3} initialUnmount>
            <Projects />
          </Hide>
          <Hide visible={section === 4} initialUnmount>
            <Partners />
          </Hide>
          <Hide visible={section === 5} initialUnmount>
            <Contact />
          </Hide>
        </>
      )}
    </>
  );
};
