import { useMemo } from "react";
import { useWindowSize } from "@better-typed/react-window-hooks";

const breakpoints = {
  x: {
    x: 1,
    s: 1,
    m: 1,
    n: 1,
    b: 1,
    l: 1,
  },
  y: {
    x: 1,
    s: 1,
    m: 1,
    n: 1,
    b: 1,
    l: 1,
  },
  z: {
    x: 1.4,
    s: 1.3,
    m: 1.1,
    n: 1,
    b: 0.9,
    l: 0.8,
  },
};

const getWidthBreakpointValue = (width: number, breakpoint: keyof typeof breakpoints) => {
  if (width < 600) {
    return breakpoints[breakpoint].x;
  }
  if (width < 1000) {
    return breakpoints[breakpoint].s;
  }
  if (width < 1300) {
    return breakpoints[breakpoint].m;
  }
  if (width < 1800) {
    return breakpoints[breakpoint].n;
  }
  if (width < 2000) {
    return breakpoints[breakpoint].b;
  }
  return breakpoints[breakpoint].l;
};

const getHeightBreakpointValue = (height: number, breakpoint: keyof typeof breakpoints) => {
  if (height < 500) {
    return breakpoints[breakpoint].x;
  }
  if (height < 700) {
    return breakpoints[breakpoint].s;
  }
  if (height < 900) {
    return breakpoints[breakpoint].m;
  }
  if (height < 1000) {
    return breakpoints[breakpoint].n;
  }
  if (height < 1100) {
    return breakpoints[breakpoint].b;
  }
  return breakpoints[breakpoint].l;
};

export const useProportion = () => {
  const [width, height] = useWindowSize();
  const proportion = useMemo(() => {
    const widthMultiplier = getWidthBreakpointValue(width, "x");
    const heightMultiplier = getHeightBreakpointValue(height, "y");
    const depthMultiplier =
      width > height ? getWidthBreakpointValue(width, "z") : getHeightBreakpointValue(height, "z");

    return [widthMultiplier, heightMultiplier, depthMultiplier] as const;
  }, [width, height]);

  return proportion;
};
