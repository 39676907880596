import { useEffect } from "react";
import { useSwipeable } from "react-swipeable";

import { useScrollPage } from "hooks/use-scroll-page.hook";
import { useSection } from "store/section.store";

type InteractivePropsType = {
  children: React.ReactNode;
};

export const Interactive: React.FC<InteractivePropsType> = ({ children }) => {
  const { prev, next, section } = useSection((state) => state);

  const onSwipedDown = () => {
    if (section !== 0) {
      prev();
    }
  };
  const onSwipedUp = () => {
    if (section !== 0) {
      next();
    }
  };

  const { ref } = useSwipeable({
    onSwipedDown,
    onSwipedUp,
    trackMouse: false,
  });

  useScrollPage(onSwipedUp, onSwipedDown);

  useEffect(() => {
    ref(document as unknown as HTMLElement);
  });

  return <div>{children}</div>;
};
