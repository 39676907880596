import { Container, MainText, SecondaryText } from "components";

import styles from "./header.module.css";

export const Header: React.FC = () => {
  return (
    <Container className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.row}>
          <h1 className={styles.title}>
            <MainText slide="right">We build extraordinary</MainText>
            <MainText slide="left">open source</MainText>
          </h1>
        </div>

        <h4 className={styles.subtitle}>
          <SecondaryText slide="bottom">We create new solutions by going beyond the limits</SecondaryText>
        </h4>
      </div>
    </Container>
  );
};
