import clsx from "clsx";

import { SecondaryText } from "components/animations";
import { ScrollDown } from "components/ui/scroll-down/scroll-down";
import { useSound } from "store/music.store";
import { useSection } from "store/section.store";
import { Container } from "../container/container";

import styles from "./footer.module.css";

export const Footer: React.FC = () => {
  const section = useSection((state) => state.section);
  const loaded = useSection((state) => state.loaded);
  const { playing, setPlaying } = useSound((state) => ({ playing: state.playing, setPlaying: state.setPlaying }));

  if (section === 0 || !loaded) {
    return null;
  }

  const handleToggle = () => {
    setPlaying(!playing);
  };

  return (
    <Container className={styles.container}>
      <SecondaryText slide="bottom">
        <div className={styles.footer}>
          <div className={styles.music}>
            <button
              type="button"
              className={clsx([styles.btn, { [styles.disabled]: !playing }])}
              onClick={handleToggle}
            >
              <span />
              <span />
              <span />
            </button>
          </div>
          <ScrollDown />
          <div className={styles.routing}>
            <a className={styles.link} href="https://github.com/BetterTyped" target="blank">
              Github
            </a>
          </div>
        </div>
      </SecondaryText>
    </Container>
  );
};
