/* eslint-disable react/no-array-index-key */
import React from "react";
import { animated, useSpring } from "react-spring";

const Letter: React.FC<{ children: React.ReactNode; index: number; delay: number }> = ({ children, index, delay }) => {
  const random = Math.random() * 0.7;
  const from = { opacity: 0 };
  const to = { opacity: Math.min(0.5 + random, 1) };
  const delayTime = Math.random() * 600 + 100 + index + delay;
  const animation = useSpring({ from, to, delay: delayTime, config: { mass: 0.4, tension: 10, friction: 15 } });
  return <animated.span style={animation}>{children}</animated.span>;
};

export const MainText: React.FC<{ children: string; slide: "left" | "right"; delay?: number }> = ({
  children,
  slide,
  delay = 0,
}) => {
  const to = { x: 0, opacity: 1 };
  const from = slide === "left" ? { x: -100, opacity: 0 } : { x: 100, opacity: 0 };

  const animation = useSpring({ from, to, delay, config: { mass: 0.2, tension: 10, friction: 10 } });

  return (
    <animated.div style={animation}>
      {children.split("").map((item, index) => (
        <Letter index={index} delay={delay} key={index}>
          {item}
        </Letter>
      ))}
    </animated.div>
  );
};
