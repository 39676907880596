import { useRef } from "react";
import { useDebounce } from "@better-typed/react-performance-hooks";
import { useWindowEvent } from "@better-typed/react-window-hooks";

import { useAnimating } from "store/animation.store";

export const useScrollPage = (onSwipeUp: () => void, onSwipeDown: () => void) => {
  const time = useRef(+new Date());
  const animating = useAnimating((state) => state.animating);
  const { debounce, reset, active } = useDebounce(100);

  const onScrollChange = (e: WheelEvent) => {
    if (+new Date() - time.current > 2500) {
      time.current = +new Date();
      const onTrigger = e.deltaY < 0 ? onSwipeDown : onSwipeUp;
      onTrigger();
    }
  };

  useWindowEvent(
    "wheel",
    (e) => {
      if (!active) {
        debounce(() => onScrollChange(e));
      }
    },
    [active, onScrollChange, animating],
  );

  return [reset];
};
